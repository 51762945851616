<template>
    <div class="scanner-container">
        <div v-show="!isLoading">
            <video ref="scanner"></video>
            <div class="overlay-element"></div>
            <div class="laser"></div>
        </div>
    </div>
</template>

<script>
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, Exception } from '@zxing/library';

export default {
    name: 'stream-barcode-reader',

    data() {
        return {
            isLoading: true,
            codeReader: null,
            isMediaStreamAPISupported:
                navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices,
        };
    },

    created() {
        const hints = new Map();
        // const formats = [BarcodeFormat.EAN_13, BarcodeFormat.EAN_8, BarcodeFormat.UPC_A];
        const formats = [BarcodeFormat.EAN_13];

        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        hints.set(DecodeHintType.TRY_HARDER, false);

        this.codeReader = new BrowserMultiFormatReader(hints);
    },

    mounted() {
        if (!this.isMediaStreamAPISupported) {
            throw new Exception('Media Stream API is not supported');
        }

        this.start();
        this.$refs.scanner.oncanplay = () => {
            this.isLoading = false;
            this.$emit('loaded');
        };
    },

    beforeUnmount() {
        this.codeReader.reset();
    },

    methods: {
        start() {
            this.codeReader.decodeFromVideoDevice(undefined, this.$refs.scanner, (result) => {
                if (result) {
                    this.$emit('decode', result.text);
                }
            });
        },
    },
};
</script>

<style scoped>
video {
    max-width: 100%;
    max-height: 100%;
}
.scanner-container {
    position: relative;
    top: 56px;
}

.overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.6);

    -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 20%,
        80% 20%,
        80% 80%,
        20% 80%,
        20% 100%,
        100% 100%,
        100% 0%
    );
    clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
}

.laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 35%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 1.5s infinite;
    animation: scanning 1.5s infinite;
}
@-webkit-keyframes scanning {
    50% {
        -webkit-transform: translateY(90px);
        transform: translateY(90px);
    }
}
@keyframes scanning {
    50% {
        -webkit-transform: translateY(90px);
        transform: translateY(90px);
    }
}
</style>
